import React from "react";
import { useDispatch, useSelector } from "react-redux"; // new import

import {
    Stack,
    Typography,
    MenuItem,
    Tooltip,
    InputAdornment,
    Box,
    Skeleton,
    Stepper,
    StepButton,
    StepContent,
    Step,
    Paper,
} from "@mui/material";

export default function ProjectSkeleton() {
    const StepperSkeleton = () => {
        const fontSize = "1.5rem";
        return (
            // Map the phases
            <Stepper nonLinear orientation="vertical">
                <Step key={"opstart"}>
                    <StepButton>
                        <Skeleton
                            width={50}
                            variant="text"
                            sx={{ fontSize: fontSize }}
                        />
                    </StepButton>
                    <StepContent>
                        <Stepper nonLinear orientation="vertical">
                            {[1, 2, 3, 4, 5].map((step) => (
                                <Step key={step}>
                                    <StepButton>
                                        <Skeleton
                                            width={100}
                                            variant="text"
                                            sx={{ fontSize: fontSize }}
                                        />
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </StepContent>
                </Step>
                <Step>
                    <StepButton>
                        <Skeleton
                            width={100}
                            variant="text"
                            sx={{ fontSize: fontSize }}
                        />
                    </StepButton>
                </Step>
            </Stepper>
        );
    };

    const fontSizes ={ 
        header: "4rem",
        textField: "2.5rem"
    }
    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                minWidth={"min-content"}
            >
                <Stack component={Paper}>
                    <Skeleton variant="rectangular" width={250} height={30} />
                    <StepperSkeleton />
                </Stack>
                <Paper>
                    <Stack
                        justifyContent={"space-between"}
                        // sx={{ width: "100%" }}
                    >
                        <Skeleton variant="text" sx={{fontSize: fontSizes.header}} width={300} />
                        <Skeleton variant="text" sx={{fontSize: fontSizes.textField}}/>
                        <Skeleton
                            variant="rectangular"
                            width={800}
                            height={100}
                        />
                        <Skeleton variant="text" sx={{fontSize: fontSizes.textField}}/>
                        <Skeleton variant="text" sx={{fontSize: fontSizes.textField}}/>
                        <Skeleton variant="text" sx={{fontSize: fontSizes.textField}}/>
                        <Skeleton variant="text" sx={{fontSize: fontSizes.textField}}/>
                    </Stack>
                </Paper>
            </Stack>

            {/* <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
            >
                <Stack>
                    <Typography paragraph>{text.description}</Typography>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            name: payload.project.name,
                            description: payload.project.description,
                            owners: payload.project.owners.map(
                                (user) => user.id
                            ),
                            collaborators: payload.project.collaborators.map(
                                (user) => user.id
                            ),
                            readers: payload.project.readers.map(
                                (user) => user.id
                            ),
                            status: payload.project.status,
                            start_date: payload.project.start_date,
                            // end_date: new Date(payload.project.end_date),
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string()
                                .max(
                                    255,
                                    "Naam mag niet meer dan 255 tekens zijn"
                                )
                                .trim()
                                .required("Naam is vereist"),
                            description: Yup.string().trim(),
                            // location: Yup.string().required("Verplicht"),
                            owners: Yup.array()
                                .required()
                                .min(1, "Kies minimaal 1 eigenaar"),
                            collaborators: Yup.array()
                                .required()
                                .min(1, "Kies minimaal 1 eigenaar"),
                            readers: Yup.array(),
                            start_date: Yup.date(
                                "Geen valide datum ingevuld"
                            ).nullable(),
                            end_date: Yup.date(
                                "Geen valide datum ingevuld"
                            ).nullable(),
                        })}
                    >
                        {({ isValid, values, setValues }) => (
                            <Form>
                                <Stack spacing={2}>
                                    {!isValid && (
                                        <Typography color="error">
                                            Formulier is niet correct ingevuld
                                        </Typography>
                                    )}

                                    <Field
                                        component={TextField}
                                        name="name"
                                        label="Naam"
                                        type="text"
                                        placeholder="Naam"
                                        InputProps={{
                                            startAdornment: helpAdornment(
                                                text.explanationName
                                            ),
                                        }}
                                        disabled={!payload.editable}
                                    />
                                    <Field
                                        component={TextField}
                                        name="description"
                                        label="Beschrijving"
                                        type="text"
                                        placeholder="Beschrijving"
                                        multiline
                                        minRows={4}
                                        maxRows={8}
                                        InputProps={{
                                            startAdornment: helpAdornment(
                                                text.explanationDescription
                                            ),
                                        }}
                                        disabled={!payload.editable}
                                    />
                                    <Field
                                        component={DatePicker}
                                        name="start_date"
                                        label="Startdatum"
                                        value={dayjs(values.start_date)}
                                        onChange={(date) => {
                                            setValues({
                                                ...values,
                                                start_date:
                                                    date.format("YYYY-MM-DD"),
                                            });
                                        }}
                                        disabled={!payload.editable}
                                    />
                                    <Field
                                        component={Select}
                                        name="owners"
                                        type="select"
                                        multiple
                                        placeholder="Eigenaren"
                                        label="Eigenaren"
                                        startAdornment={helpAdornment(
                                            text.explanationOwners
                                        )}
                                        onClose={() => {}}
                                        disabled={!payload.editable}
                                    >
                                        {userOptions.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <Field
                                        component={Select}
                                        name="collaborators"
                                        type="select"
                                        multiple
                                        placeholder="Medewerkers"
                                        label="Medewerkers"
                                        startAdornment={helpAdornment(
                                            text.explanationCollaborators
                                        )}
                                        onClose={() => {}}
                                        disabled={!payload.editable}
                                    >
                                        {userOptions.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    <Field
                                        component={Select}
                                        name="readers"
                                        type="select"
                                        multiple
                                        placeholder="Lezers"
                                        label="Lezers"
                                        startAdornment={helpAdornment(
                                            text.explanationReaders
                                        )}
                                        onClose={() => {}}
                                        disabled={!payload.editable}
                                    >
                                        {userOptions.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                    {payload.project.user_is_orgadmin ? (
                                        <Field
                                            component={Select}
                                            name="status"
                                            type="select"
                                            placeholder="Status"
                                            label="Status"
                                            startAdornment={helpAdornment(
                                                text.status
                                            )}
                                            onClose={() => {}}
                                            disabled={!payload.editable}
                                        >
                                            {projectStatus.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    ) : (
                                        ""
                                    )}
                                </Stack>
                                <br />
                                <StepperButtons
                                    payload={payload}
                                    saveAction={handleUpdateProject}
                                    saveActionArguments={{
                                        ...values,
                                        id: payload.project.id,
                                    }}
                                    updateStatus={payload.project.status === 0}
                                />
                            </Form>
                        )}
                    </Formik>
                </Stack>
                <Box>
                    <Typography paragraph>{text.attachments}</Typography>
                    <AttachmentTable
                        parentType={"project"}
                        parentObj={payload.project}
                        parentIds={{ project: payload.project.id }}
                        showHeader={true}
                        defaultExpanded={false}
                    />
                </Box>
            </Stack> */}
        </>
    );
}
