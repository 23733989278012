import React from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import ProjectMain from "../components/Projects/ProjectMain";
import ProjectSkeleton from "../components/Projects/PreparationPhase/Step_1_ProjectSummary-Skeleton";

export default function ProjectPage() {
    const project = useSelector((state) => state.projects.selectedProject);
    const isLoading = useSelector((state) => state.projects.loading);

    if (!project || isLoading === true) {
        return <ProjectSkeleton />;
    }

    return (
        // <Container maxWidth={false}>
        <ProjectMain project={project} />
        // </Container>
    );
}
