import { React, useState } from "react";

import {
    Button,
    ButtonGroup,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { Add, CloseFullscreen, Delete, Download, Edit, OpenInFull } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import AttachmentDialog from "./AttachmentDialog";
import { fetcher } from "../../utils/axios";
import { formatFileSize, shortenString } from "../../functions/Formatters";
import { ImageDialog } from "./ImageDialog";
import { DownloadFile, createAttachment, deleteAttachment, updateAttachment } from "./AttachmentActions";
import ShortStringWithTooltip from "../CustomComponents/ShortStringWithTooltip";

/**
 * Renders a table of attachments with various actions such as editing, deleting, and downloading.
 * @param {Object} props - The component props.
 * @param {string} props.parentType - The type of the parent entity. Options are "project", "design", "advice"
 * @param {Object} props.parentIds - Object containing the id number of the relevant parent entities. For example, if the parentType is "project", the parentIds should contain a field "project" with the id of the project.
 * @param {Object} [props.parentIds.project] - The id of the project.
 * @param {Object} [props.parentIds.phase] - The id of the phase.
 * @param {Object} [props.parentIds.loop] - The id of the loop.
 * @param {Object} [props.parentIds.design] - The id of the design.
 * @param {Object} [props.parentIds.advice] - The id of the advice.
 * @param {Object} [props.parentIds.projectTheme] - The id of the project theme.
 * @param {Object} [props.parentIds.projectSubtheme] - The id of the project subtheme.
 * @param {Object} props.parentObj - The object of the parent entity. Should contain a field "attachments" which is an array of attachment objects.
 * @param {Object} props.parentObj.id - The id of the parent entity.
 * @param {Object} props.parentObj.attachments - Array of attachment objects.
 * @param {boolean} [props.defaultExpanded=false] - Whether the table should be initially expanded. I.e. whether to show the attachment description, size and filetype
 * @param {boolean} [props.showHeader=true] - Whether to show the table header.
 * @returns {JSX.Element} The rendered AttachmentTable component.
 */
export default function AttachmentTable(props) {
    const dispatch = useDispatch();
    const { parentType, parentObj, parentIds } = props;
    const attachments = parentObj?.attachments || [];
    
    const [fullWidth, setFullWidth] = useState(props.defaultExpanded || false);
    
    let newAttachment = {
        id: "",
        name: "",
        file: "",
        description: "",
    };

    // States for attachments that are being edited or deleted.
    const [attachmentToEdit, setAttachmentToEdit] = useState(newAttachment);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = useState();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    // State and handler, used when downloading an attachment that is an image.
    const [image, setImage] = useState({ open: false, image: null, filename: "" });
    const handleImageDialogClose = () => {
        setImage({ open: false, image: null, filename: "" });
    };

    async function handleCreateNewAttachment(attachment) {
        createAttachment(attachment, parentType, parentIds, parentObj, dispatch);
    }
    // Function to handle updating an attachment. Only updates the name and description fields.
    // Updating the file is not supported.
    async function handleUpdateAttachment(attachment) {
        updateAttachment(attachment, parentType, parentIds, parentObj, dispatch);
    }
    async function handleDeleteAttachment() {
        deleteAttachment(attachmentToDelete, parentType, parentIds, parentObj, dispatch);
        setDeleteDialogOpen(false);
    }
    const handleDeleteClick = (id) => {
        setAttachmentToDelete(id);
        setDeleteDialogOpen(true);
    };
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };
    const handleEditClick = (attachment) => {
        setAttachmentToEdit(attachment);
        setEditDialogOpen(true);
    };
    const handleSave = (attachment) => {
        if (attachment.get("id")) {
            handleUpdateAttachment(attachment);
        } else {
            handleCreateNewAttachment(attachment);
        }
    };

    /**
     * Handles the click event for downloading an attachment.
     * If the attachment is an image, it will be opened in a dialog.
     * Otherwise, it will be downloaded.
     * @param {Object} attachment - The attachment object.
     */
    const handleDownloadClick = (attachment) => {
        fetcher(`/attachments/${attachment.id}/download/`, "blob").then((response) => {
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
                name: attachment.filename,
            });
            const blobURL = URL.createObjectURL(blob);

            if (blob.type.includes("image")) {
                setImage({ open: true, image: blobURL, filename: attachment.filename });
                return;
            } else {
                DownloadFile(blobURL, attachment.filename);
            }
        });
    };

    if (parentObj === undefined) {
        // Not sure what this is supposed to catch
        return null;
    }

    return (
        <>
            <ConfirmDeleteDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                dialogTitle="Bijlage verwijderen"
                dialogContentText="Weet je zeker dat je deze bijlage wilt verwijderen?"
                handleConfirm={handleDeleteAttachment}
                dialogConfirmButton="Bijlage verwijderen"
            />
            <AttachmentDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                attachment={attachmentToEdit}
                setAttachment={setAttachmentToEdit}
                handleSave={handleSave}
                handleDelete={handleDeleteClick}
                handleDownload={handleDownloadClick}
            />
            <ImageDialog image={image} onClose={handleImageDialogClose} />
            <Stack sx={{ flex: 0, p: 0, m: 0, width: "max-content" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                    {attachments.length > 0 && (
                        <Tooltip placement="top-end" title={fullWidth ? "Verberg details" : "Toon details"}>
                            <IconButton sx={{ rotate: "45deg" }} onClick={() => setFullWidth(!fullWidth)}>
                                {fullWidth ? <CloseFullscreen fontSize="small" /> : <OpenInFull fontSize="small" />}
                            </IconButton>
                        </Tooltip>
                    )}
                    <TableContainer sx={{ width: "max-content" }}>
                        <Table size="small">
                            {props.showHeader && attachments.length > 0 && (
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography fontWeight={"bold"}>Naam</Typography>
                                        </TableCell>
                                        {fullWidth && (
                                            <>
                                                <TableCell>
                                                    <Typography fontWeight={"bold"}>Beschrijving</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight={"bold"}>Type</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight={"bold"}>Grootte</Typography>
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography alignSelf={"center"} fontWeight={"bold"}>
                                                    Acties
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {attachments.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <ShortStringWithTooltip string={item.name} maxLength={20} />
                                        </TableCell>
                                        {fullWidth && (
                                            <>
                                                <TableCell>
                                                    <ShortStringWithTooltip string={item.description} />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>.{item.filename.split(".").pop()}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{formatFileSize(item.filesize)}</Typography>
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell>
                                            <ButtonGroup>
                                                <IconButton onClick={() => handleEditClick(item)}>
                                                    <Tooltip placement="top-start" title={"bewerken"}>
                                                        <Edit fontSize="small" />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteClick(item.id)}>
                                                    <Tooltip placement="top-start" title={"Verwijderen"}>
                                                        <Delete fontSize="small" />
                                                    </Tooltip>
                                                </IconButton>
                                                <IconButton onClick={() => handleDownloadClick(item)}>
                                                    <Tooltip placement="top-start" title={"Downloaden"}>
                                                        <Download fontSize="small" />
                                                    </Tooltip>
                                                </IconButton>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={fullWidth ? 5 : 2}>
                                        <ButtonGroup variant="text">
                                            <Button onClick={() => handleEditClick(newAttachment)}>
                                                <Add />
                                                Bijlage toevoegen
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Stack>
            </Stack>
        </>
    );
}
